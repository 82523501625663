<template>
  <div>
    <ProgressBar v-if="uploading" :uploading="uploading" />
    <div class="flex justify-end items-center">
      <button
        class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white p-1 border border-blue-500 hover:border-transparent rounded-full h-15 w-15 flex items-center justify-center"
        @click="openModal = true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-10 w-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
      </button>
    </div>

    <div class="grid grid-cols-4 gap-10 px-16 py-6">
      <div class="relative" v-for="cat in categories" :key="cat.id">
        <router-link :to="{
          name: 'photoCollection',
          params: { categoryId: cat.id }
        }">
          <div class="bg-white rounded-lg shadow-lg relative h-40">
            <img class="object-cover h-40 w-full" :src="cat.image">
            <div class="absolute top-0 bottom-0 text-center left-0 right-0 flex justify-around items-center">
              <h2 class="font-bold mb-2 text-2xl text-white capitalize">
                {{cat.name}}
              </h2>
            </div>
          </div>
          <button class="w-10 h-10 bg-red-600 text-white rounded-md absolute -top-4 -right-4" @click.prevent='deleteCategory(cat)'>X</button>
        </router-link>
      </div>
    </div>

    <Modal :showing="openModal" @close="openModal = false">
      <h2>Create a new category</h2>

      <form class="flex flex-col">
        <input
          v-model="categoryName"
          type="text"
          class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm rounded-md border-2 border-black"
          placeholder="Category name"
        >
        <div>
          <label class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            <input type="file"
              @change="hendleImageChange"
              class="opacity-0 h-0 w-0"
            >
            <span>Upload Category image</span>
          </label>

          <div class="">
            <img class="h-60" :src="categoryPrevImage" :alt="imageName">
          </div>
        </div>
        
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click.prevent='upload'>upload</button>
      </form>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/Modal'
import ProgressBar from '@/components/ProgressBar'

export default {
  name: "categoriesList",
  components: {
    Modal, ProgressBar
  },
  data() {
    return {
      file: null,
      openModal: false,
      categoryName: '',
      categoryPrevImage: null,
      imageName: '',
      imageTypes: ['image/png', 'image/jpeg', 'image/jpg'],
    }
  },
  computed: {
    ...mapGetters(['categories', 'uploading'])
  }, 
  methods: {
    hendleImageChange(e){
      this.file = e.target.files[0]
      if (this.file && this.imageTypes.includes(this.file.type)) {
        this.categoryPrevImage = URL.createObjectURL(this.file)
        this.imageName = this.file.name
      }
    },
    upload() {
      const data = {
        name: this.categoryName,
        image: this.file
      }
      this.$store.dispatch("addCategory", {categoryName: data.name, file: data.image})

      this.categoryName = ''
      this.categoryPrevImage = null
      this.imageName = ''
      
      this.openModal = false
    },
    deleteCategory(cat) {
      this.$store.dispatch("deleteCategory", cat)
    }
  },
  created(){
    this.$store.dispatch('getCategories')
  }
};
</script>

<style></style>
